<template>
  <div class="wrap">
    <div class="tab-wrap">
      <!-- <span class="drawer-main" > -->
      <el-button
        class="btn"
        v-for="(item,idx) in drawerFormData.drawerBtn"
        :key="idx"
        @click="drawerClick(item.name)"
      >{{ item.name }}</el-button>
      <!-- </span> -->
    </div>
    <!-- :class="drawerKey === item.name ? 'drawer-btn1' : 'drawer-btn'" -->
    <!-- 基本信息 -->
    <div class="drawer-contract base-info" v-show="drawerKey === '基本信息'">
      <el-form :model="baseInfo" ref="form" :inline="true" label-position="left" style>
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="标段类型:">
              <span>{{ baseInfo.bidTypeDes | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="标段编号:">
              <span>{{ baseInfo.bidNum | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="标段名称:">
              <span>{{ baseInfo.bidName | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="面积:">
              <span>{{ baseInfo.bidContractArea | isNull }}(平方米)</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="主管单位:">
              <span>{{ baseInfo.bidManageUnit | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="所属项目组:">
              <span>{{ baseInfo.projectGroupId | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="养护企业:">
              <span>{{ baseInfo.bidMaintainUnit | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="人员(不通过/总数):">
              <span>{{ baseInfo.checkFailedPersonCount | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="车辆(不通过/总数):">
              <span>{{ baseInfo.checkFailedCarCount | isNull }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-show="drawerKey === '基本信息'" class="drawer-map">
      <area-map v-model="mapData" :accuracy="6" @change="onMapChange" :enableDraw="false"></area-map>
      <!-- <div class="drawer-map-title">标段范围</div>
      <div>

      </div>-->
    </div>
    <!-- 标段合同 -->
    <div class="drawer-contract" style="padding-top: 20px" v-show="drawerKey === '标段合同'">
      <el-form
        :model="contractInfo"
        ref="form"
        :inline="true"
        label-width="170px"
        label-position="right"
      >
        <el-row :gutter="10">
          <el-col class="contract-item" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item label="合同名称：">
              <span>{{ contractInfo.contractName | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col class="contract-item" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item label="合同编号：">
              <span>{{ contractInfo.contractNum | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col class="contract-item" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item label="合同周期：">
              <span>{{contractInfo.contractStartTime | str2ymd}}</span>
              <span v-if="contractInfo.contractStartTime" style="padding: 0 5px">至</span>
              <span v-if="contractInfo.contractEndTime">{{contractInfo.contractEndTime| str2ymd}}</span>
            </el-form-item>
          </el-col>
          <el-col class="contract-item" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item label="采购单位：">
              <span>{{ contractInfo.partyAUnit | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col class="contract-item" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item label="甲方联系人：">
              <span>{{ contractInfo.partyAContact | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col class="contract-item" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item label="联系方式：">
              <span>{{ contractInfo.partyAPhone | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col class="contract-item" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item label="乙方联系人：">
              <span>{{ contractInfo.partyBContact | isNull }}</span>
            </el-form-item>
          </el-col>
          <el-col class="contract-item" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item label="联系方式：">
              <span>{{ contractInfo.partyBPhone | isNull }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 人员 -->
    <div class="drawer-contract" v-show="drawerKey === '人员'">
      <ayl-table :table="personnelTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>
    <!-- 车辆 -->
    <div class="drawer-contract" v-show="drawerKey === '车辆'">
      <ayl-table :table="carTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>
    <!-- 城中村 -->
    <div class="drawer-contract" v-show="drawerKey === '城中村'">
      <ayl-table :table="villageTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>
    <!-- 市政道路 -->
    <div class="drawer-contract" v-show="drawerKey === '市政道路'">
      <ayl-table :table="cityRoadTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>

    <!-- 垃圾中转站 -->
    <div class="drawer-contract" v-show="drawerKey === '垃圾中转站'">
      <ayl-table :table="rubbishTransferTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>
    <!-- 垃圾收集点 -->
    <div class="drawer-contract" v-show="drawerKey === '垃圾收集点'">
      <ayl-table :table="rubbishCollectorTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>
    <!-- 国土地块 -->
    <div class="drawer-contract" v-show="drawerKey === '国土地块'">
      <ayl-table :table="plotTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>
    <!-- 环保垃圾屋 -->
    <div class="drawer-contract" v-show="drawerKey === '环保垃圾屋'">
      <ayl-table :table="ecoHouseTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>
    <!-- 公厕 -->
    <div class="drawer-contract" v-show="drawerKey === '公厕'">
      <ayl-table :table="publicToiletTable" @selection-change="handleSelectionChange">
        <div slot="ctrl-button">
          <el-button class="btn" @click="openDrawer">调入</el-button>
          <el-button class="btn" style="margin-left: 20px" @click="transferredOut">调出</el-button>
          <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
        </div>
      </ayl-table>
    </div>
    <el-drawer
      :title="drawerKey + '调入'"
      :append-to-body="true"
      :before-close="handleClose"
      :visible.sync="innerDrawer"
      size="50%"
    >
      <personnel-transferred v-if="drawerKey === '人员'"></personnel-transferred>
      <vehicle-transferred v-if="drawerKey === '车辆'"></vehicle-transferred>
      <urban-village-transferred v-if="drawerKey === '城中村'"></urban-village-transferred>
      <city-road-transferred v-if="drawerKey === '市政道路'"></city-road-transferred>
      <garbage-transfer-transferred v-if="drawerKey === '垃圾中转站'"></garbage-transfer-transferred>
      <garbage-collection-transferred v-if="drawerKey === '垃圾收集点'"></garbage-collection-transferred>
      <land-transfer v-if="drawerKey === '国土地块'"></land-transfer>
      <garbage-green-transferred v-if="drawerKey === '环保垃圾屋'"></garbage-green-transferred>
      <public-toilets-transfer v-if="drawerKey === '公厕'"></public-toilets-transfer>
    </el-drawer>
  </div>
</template>

<script>
import AreaMap from "../../operation-safety-management/operating-rules/AreaMap";
export default {
  name: "DistrictSummaryDetails",
  components: {
    AreaMap,
    PersonnelTransferred: () => import("./personnel-transferred"),
    VehicleTransferred: () => import("./vehicle-transferred"),
    UrbanVillageTransferred: () => import("./urban-village-transferred"),
    CityRoadTransferred: () => import("./city-road-transferred"),
    GarbageTransferTransferred: () => import("./garbage-transfer-transferred"),
    GarbageCollectionTransferred: () =>
      import("./garbage-collection-transferred"),
    LandTransfer: () => import("./land-transfer"),
    GarbageGreenTransferred: () => import("./garbage-green-transferred"),
    PublicToiletsTransfer: () => import("./public-toilets-transfer"),
  },
  props: {
    summaryId: String,
  },
  data() {
    const vm = this;
    return {
      innerDrawer: false,
      drawerKey: "基本信息", //标识
      drawerFormData: {
        title: "标题",
        //按钮信息
        drawerBtn: [
          {
            name: "基本信息",
          },
          {
            name: "标段合同",
          },
          {
            name: "人员",
          },
          {
            name: "车辆",
          },
          {
            name: "城中村",
          },
          {
            name: "市政道路",
          },
          {
            name: "垃圾中转站",
          },
          {
            name: "垃圾收集点",
          },
          {
            name: "国土地块",
          },
          {
            name: "环保垃圾屋",
          },
          {
            name: "公厕",
          },
        ],
      },

      baseInfo: {},
      contractInfo: {},
      idList: [],
      mapData: {
        polygonScope: null, //（1个）多边形具体的点，形如  [[118,36],[117,32],[116,113]]
        scope: null, //（1个）围栏范围，经纬度点阵数组，形如  [[118,36],[117,32],[116,113]]
      },
      //人员表格
      personnelTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailPersonPage,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "请输入姓名",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "姓名",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.name || "- -"
                ),
              ]);
            },
          },
          {
            title: "联系方式",
            // width: "180px",
            showTooltip: true,
            key: "phone",
          },
          {
            title: "人员类型",
            key: "personTypeDes",
            // filter: "str2ymd",
            // width: "160px",
          },
          {
            title: "变更类型",
            // width: "120px",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            width: "180px",
            key: "checkStatusDes",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
      //车辆表格
      carTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailCarPage,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "车牌号",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "车牌号",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.plateNumber || "- -"
                ),
              ]);
            },
          },
          {
            title: "车辆类型",
            showTooltip: true,
            key: "carTypeDesc",
          },
          {
            title: "变更类型",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            // width: "180px",
            key: "carStatusDesc",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
      //城中村表格
      villageTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailVillagePage,

        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "城中村名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "城中村名称",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.villageName || "- -"
                ),
              ]);
            },
          },
          {
            title: "人口数量",
            showTooltip: true,
            key: "population",
          },
          {
            title: "清扫保洁面积（m2)",
            key: "cleanArea",
          },
          {
            title: "范围",
            // width: "180px",
            key: "villageScope",
          },
          {
            title: "变更类型",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            key: "checkStatusDes",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
      //市政道路表格
      cityRoadTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailCityRoadPage,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "请输入市政道路名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "市政道路名称",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.roadName || "- -"
                ),
              ]);
            },
          },
          {
            title: "长度(m)",
            showTooltip: true,
            key: "mainRoadLen",
          },
          {
            title: "主车道面积（m²)",
            showTooltip: true,
            key: "mainRoadArea",
          },
          {
            title: "保洁等级",
            key: "cleanLevelDesc",
          },
          {
            title: "是否机扫",
            // width: "180px",
            key: "isSweeper",
          },
          {
            title: "变更类型",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            key: "checkStatusDes",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
      //垃圾中转站表格
      rubbishTransferTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailRubbishTransferPage,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "请输入垃圾中转站名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "垃圾中转站名称",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.transferName || "- -"
                ),
              ]);
            },
          },
          {
            title: "中转站类型",
            showTooltip: true,
            key: "transferTypeDes",
          },
          {
            title: "地址",
            showTooltip: true,
            key: "address",
          },
          {
            title: "有无公厕",
            key: "isContainedWc",
          },
          {
            title: "变更类型",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            key: "checkStatusDes",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
      //垃圾收集点表格
      rubbishCollectorTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailRubbishCollectorPage,

        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "请输入垃圾收集点名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "垃圾收集点名称",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.collectName || "- -"
                ),
              ]);
            },
          },
          {
            title: "开放时间",
            showTooltip: true,
            key: "openStartTime", //openEndTime
          },
          {
            title: "清运量（吨/天）",
            showTooltip: true,
            key: "cleanTon",
          },
          {
            title: "地址",
            key: "collectAddress",
          },
          {
            title: "变更类型",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            key: "checkStatusDes",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
      //国土地块表格
      plotTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailPlotPage,

        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "国土地块名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "国土地块名称",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.villageName || "- -"
                ),
              ]);
            },
          },
          {
            title: "人口数量",
            showTooltip: true,
            key: "population",
          },
          {
            title: "清扫保洁面积m2)",
            showTooltip: true,
            key: "cleanArea",
          },
          {
            title: "范围",
            key: "villageScope",
          },
          {
            title: "变更类型",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            key: "checkStatusDes",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
      //垃圾环保屋表格
      ecoHouseTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailEcoHousePage,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "垃圾环保屋名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "垃圾环保屋名称",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.ecoName || "- -"
                ),
              ]);
            },
          },
          {
            title: "环保垃圾屋类型",
            showTooltip: true,
            key: "ecoTypeDesc",
          },
          {
            title: "地址",
            showTooltip: true,
            key: "ecoAddress",
          },
          {
            title: "变更类型",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            key: "checkStatusDes",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
      //公厕表格
      publicToiletTable: {
        api: this.$api_hw.districtInfo_bidSummaryDetailPublicToiletPage,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "公厕名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "公厕名称",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.wcName || "- -"
                ),
              ]);
            },
          },
          {
            title: "建成时间",
            showTooltip: true,
            key: "buildTime",
          },
          {
            title: "管理人员",
            showTooltip: true,
            key: "demandWorkerName",
          },
          {
            title: "男厕便池",
            key: "maleStandNum",
          },
          {
            title: "女厕蹲位",
            key: "femaleSquatNum",
          },
          {
            title: "水龙头",
            key: "faucetNum",
          },
          {
            title: "镜子",
            key: "mirrorNum",
          },
          {
            title: "抽风机",
            key: "suctionFanNum	",
          },
          {
            title: "风扇",
            key: "fanNum",
          },
          {
            title: "洗手液",
            key: "hasHandSanitizer",
          },
          {
            title: "免费厕纸",
            key: "hasFreePaper",
          },
          {
            title: "变更状态",
            key: "changeTypeDes",
          },
          {
            title: "状态",
            key: "checkStatusDes",
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
        ],
      },
    };
  },
  filters: {
    isNull: (value) => {
      return value !== 0 && !value ? "--" : value;
    },
  },
  methods: {
    //选择按钮信息
    async drawerClick(val) {
      this.drawerKey = val;
      this.idList = [];
      console.log(val);

      switch (val) {
        case "基本信息":
          break;
        case "标段合同":
          this.getBildContract();
          break;
        case "人员":
          this.$set(this.personnelTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
        case "车辆":
          this.$set(this.carTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
        case "城中村":
          this.$set(this.villageTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
        case "市政道路":
          this.$set(this.cityRoadTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
        case "垃圾中转站":
          this.$set(this.rubbishTransferTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
        case "垃圾收集点":
          this.$set(this.rubbishCollectorTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
        case "国土地块":
          this.$set(this.plotTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
        case "环保垃圾屋":
          this.$set(this.ecoHouseTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
        case "公厕":
          this.$set(this.publicToiletTable, "query", {
            id: this.summaryId,
            queryContent: null,
          });
          break;
      }
    },

    onMapChange(mapData) {},

    // 调入抽屉
    handleClose() {
      this.innerDrawer = false;
    },
    openDrawer() {
      this.innerDrawer = true;
    },

    handleSelectionChange(val) {
      let identifier = null;
      switch (this.drawerKey) {
        case "人员":
          identifier = "personId";
          break;
        case "车辆":
          identifier = "plateNumber";
          break;
        case "城中村":
          identifier = "villageId";
          break;
        case "市政道路":
          identifier = "villageId";
          break;
        case "垃圾中转站":
          identifier = "transferId";
          break;
        case "垃圾收集点":
          identifier = "collectId";
          break;
        case "国土地块":
          identifier = "villageId";
          break;
        case "环保垃圾屋":
          identifier = "ecoId";
          break;
        case "公厕":
          identifier = "wcId";
          break;
      }

      console.log();
      this.idList = val.map((item) => {
        return item[identifier];
      });
      console.log(this.idList);
    },

    // 调出
    transferredOut() {
      if (this.isMultiple) return;
      this.isMultiple = true;

      let obj = {};

      switch (this.drawerKey) {
        case "人员":
          obj = {
            type: 7,
            tableName: "personnelTable",
          };
          break;
        case "车辆":
          obj = {
            type: 8,
            tableName: "carTable",
          };
          break;
        case "城中村":
          obj = {
            type: 1,
            tableName: "villageTable",
          };
          break;
        case "市政道路":
          obj = {
            type: 0,
            tableName: "cityRoadTable",
          };
          break;
        case "垃圾中转站":
          obj = {
            type: 3,
            tableName: "rubbishTransferTable",
          };
          break;
        case "垃圾收集点":
          obj = {
            type: 4,
            tableName: "rubbishCollectorTable",
          };
          break;
        case "国土地块":
          obj = {
            type: 2,
            tableName: "plotTable",
          };
          break;
        case "环保垃圾屋":
          obj = {
            type: 5,
            tableName: "ecoHouseTable",
          };
          break;
        case "公厕":
          obj = {
            type: 6,
            tableName: "publicToiletTable",
          };
          break;
      }

      let params = {
        idList: this.idList,
        type: obj.type,
      };
      this.$api_hw.districtInfo_bidSummaryDetailBringIn(params).then((res) => {
        this.$notify({
          title: "成功",
          message: "数据调出成功",
          type: "success",
          showClose: false,
        });
        this.isMultiple = false;
        this.$search(this[obj.tableName]);
      });
    },

    /**
     * 查看标段信息
     */
    previewDistrictDetails(val) {
      this.$router.push({
        path: "/district-management/district-info/district-details",
        query: { id: val.bidId },
      });
    },

    // 获取基本信息
    getDetailsInfo() {
      this.$api_hw
        .districtInfo_bidSummaryDetailBase({ id: this.summaryId })
        .then((res) => {
          this.baseInfo = res;
          try {
            this.mapData.polygonScope = JSON.parse(res.bidCoordinateScope);
            this.mapData.move2center = true;
          } catch (error) {
            this.$message.error("获取区域范围失败");
          }
        });
    },
    // 获取标段合同
    async getBildContract() {
      this.contractInfo = await this.$api_hw.districtInfo_bidSummaryDetailContract(
        {
          id: this.summaryId,
        }
      );
    },
  },

  created() {
    this.getDetailsInfo();
  },
};
</script>

<style lang="sass" scoped>
.wrap
  display: flex;
  flex-direction: column;
  padding: 20px
  padding-top: 80px;
  box-sizing: border-box;
  height: 100%
  
.tab-wrap
  display: flex
  flex-wrap: wrap
  margin-bottom: 20px;
  padding: 20px 12px 10px;
  box-sizing: border-box
  box-shadow: 0 0 4px rgba(10,10,10,0.3)


.btn
  margin-right: 10px;
  margin-bottom: 10px;
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
.drawer-main
  margin-right: 8px
.drawer-btn
  width: 90px
  height: 28px
  margin-bottom: 10px
  // background: rgba(237,237,237,1)
  border-radius: 2px
  outline: none
  border: 0
  &:hover
    margin-bottom: 10px
    background: rgba(26,188,156,1)
    border-radius: 2px
    border: 0
    color: #fff
    &:before
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      border: 0
      color: #fff
.drawer-btn1
  width: 90px
  height: 28px
  margin-bottom: 10px
  background: rgba(26,188,156,1)
  border-radius: 2px
  outline: none
  border: 1px solid transparent
  color: #fff

.base-info
  padding: 12px 10px 0
  border: 1px dashed #9e9e9e;
  border-radius: 4px;
.drawer-contract-item
  // width: 100%
  // height: 1px
  // background: rgba(232,232,232,1)
.drawer-map
  margin-top: 20px;
  height: 100%
  border: 1px solid #ddd;
  .drawer-map-title
    color: rgba(46,48,51,1)
/deep/.el-button--success 
    color: #FFFFFF
    background-color: rgba(26,188,156,1)
    border-color: rgba(26,188,156,1)
/deep/.el-drawer__body
    padding-top: 70px




/deep/ .el-button + .el-button
    margin-right: 10px
    margin-left: 0
.contract-item /deep/ .el-form-item--mini.el-form-item
  display: flex
.contract-item /deep/ .el-form-item__content
  border-bottom: 1px solid #9e9e9e
  width: 100%
.ctrl-btn .btn
  margin-bottom: 0;
</style>